import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { scroller } from 'react-scroll';

// Material Design
import TopAppBar, {
  TopAppBarFixedAdjust,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarIcon,
} from '@material/react-top-app-bar';
import { Body1 } from '@material/react-typography';
import MaterialIcon from '@material/react-material-icon';

import Menu from './menu';

// Styles
import '../../styles/sass/top-app-bar.scss';

// Images
import logo from '../../images/logo.svg';

const Header = ({ siteTitle }) => {
  return (
    <header>
      <Menu />
      <TopAppBar className="mdc-header">
        <TopAppBarRow>
          <TopAppBarSection align="start">
            <TopAppBarTitle>
              <Link to="/">
                <img alt={siteTitle} src={logo} className="mdc-logo" />
              </Link>
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection align="end" className="mdc-hidden-tablet">
            <TopAppBarIcon navIcon tabIndex={0}>
              <MaterialIcon hasRipple icon="menu" />
            </TopAppBarIcon>
          </TopAppBarSection>

          <TopAppBarSection align="end" className="mdc-hidden-phone">
            <Body1
              onClick={() => {
                scroller.scrollTo('quienes-somos', {
                  delay: 100,
                  smooth: true,
                });
              }}
            >
              ¿Quienes somos?
            </Body1>
            <Body1
              onClick={() => {
                scroller.scrollTo('comunidad', { delay: 100, smooth: true });
              }}
            >
              Comunidad CMujer
            </Body1>
            <Body1
              onClick={() => {
                scroller.scrollTo('eventos', { delay: 100, smooth: true });
              }}
            >
              Eventos
            </Body1>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
