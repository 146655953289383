import React from 'react';
import { scroller } from 'react-scroll';

// Material Components
import {
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  DrawerSubtitle,
} from '@rmwc/drawer';
import { List, ListItem } from '@rmwc/list';

// Estilos
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';

const Menu = () => {
  return (
    <Drawer modal open={false}>
      <DrawerHeader>
        <DrawerTitle>Menú</DrawerTitle>
        <DrawerSubtitle>CMujer</DrawerSubtitle>
      </DrawerHeader>
      <DrawerContent>
        <List>
          <ListItem
            onClick={() => {
              scroller.scrollTo('quienes-somos', {
                delay: 100,
                smooth: true,
              });
            }}
          >
            ¿Quienes somos?
          </ListItem>
          <ListItem
            onClick={() => {
              scroller.scrollTo('comunidad', {
                delay: 100,
                smooth: true,
              });
            }}
          >
            Comunidad CMujer
          </ListItem>
          <ListItem
            onClick={() => {
              scroller.scrollTo('eventos', {
                delay: 100,
                smooth: true,
              });
            }}
          >
            Eventos
          </ListItem>
        </List>
      </DrawerContent>
    </Drawer>
  );
};

export default Menu;
