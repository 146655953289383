import React from 'react';
import { Link } from 'gatsby';

// Material components
import { Cell, Grid, Row } from '@material/react-layout-grid';
import { Body2 } from '@material/react-typography';

// Special Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

// Images
import logoCmujer from '../../images/logo-cmujer.svg';

const Footer = () => {
  return (
    <div className="mdc-section--footer">
      <Grid>
        <hr className="mdc-submain-separator" />
        <Row>
          <Cell columns={12} className="mdc-align__center">
            <img alt="" src={logoCmujer} />

            <Body2>Síguenos</Body2>
            <div>
              <a
                href="https://www.facebook.com/MUJERUTVCO/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://instagram.com/cmujerlider"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>

              <a
                href="https://twitter.com/cmujer_ut?lang=es"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>

            <br />
            <Body2>
              <span>
                <Link to="/">Inicio&nbsp;</Link>
              </span>
              <span>
                <Link to="/politica-de-privacidad">
                  - Política de privacidad
                </Link>
              </span>
            </Body2>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default Footer;
