import React from 'react';
import { Formik } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';

// Material Design
import { Headline5 } from '@material/react-typography';
import TextField, { Input } from '@material/react-text-field';
import Button from '@material/react-button';

const NewsletterForm = () => (
  <>
    <Headline5>Envíanos tu correo y recibe todas nuestras noticias.</Headline5>
    <Formik
      initialValues={{ email: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'El correo electrónico es necesario';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'El correo electrónico está mal escrito';
        }
        return errors;
      }}
      onSubmit={({ email }, { setSubmitting }) => {
        addToMailchimp(email)
          .then((data) => console.log(data))
          .catch((error) => console.log(error));

        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <TextField label="Correo electrónico">
            <Input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              name="email"
            />
          </TextField>

          <br />
          <br />
          <Button unelevated type="submit">
            {!isSubmitting ? 'Enviar' : 'Enviando'}
          </Button>
        </form>
      )}
    </Formik>
  </>
);

export default NewsletterForm;
