import React from 'react';

// Material Components
import { Grid, Cell, Row } from '@material/react-layout-grid';
import { Headline3 } from '@material/react-typography';

// Components
import NewsletterForm from '../forms/newsletter-form';

const Newsletter = () => (
  <>
    <Grid className="mdc-section--newsletter">
      <Row>
        <Cell columns={12}>
          <Headline3 style={{ marginBottom: 200 }}>
            Suscríbete a nuestro boletín
          </Headline3>
          <NewsletterForm />
        </Cell>
      </Row>
    </Grid>
  </>
);

export default Newsletter;
